<template>
  <div class="kecheng">
    <kind-choose-view
      :show-kc="false"
      :type="116"
      :entrants-type="3"
      @isOk="isOk"
    />

    <!-- 内容 -->
    <div v-if="list && list.length > 0" class="live">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item"
        @click="godetail(item)"
      >
        <div class="clickNum">
          <img class="item-img" src="@/assets/img/homeSeventh/clickIcon.png">
          <div class="num">{{ item.clicks }}人气值</div>
        </div>
        <!-- 活动详情 -->
        <img class="item-img" :src="item.img">
        <div class="detail">
          <div class="detail-title twoEllipsis">{{ item.title }}</div>
          <div class="bottom flex-between-c">
            <div class="tips">
              <i class="iconfont icon-daishouhuo" />{{ item.startTime }}
            </div>
            <span class="ter">讲师：{{ item.terName }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 暂无课程 -->
    <div v-if="!list || list.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <div class="flex-jc-c m-t-20">
      <el-pagination
        class="pagination colleges-pagination"
        :total="total"
        :current-page="pageNum"
        :page-sizes="[12, 24,36,48]"
        :page-size="pageSize"
        :pager-count="5"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <el-dialog
      title="提示"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="ifHave"
      width="400px"
      center
    >
      <span>尚未购买无法观看</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="ifHave = false">取消</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : "" }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : "" }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import KindChooseView from '../../components/kindChooseViewzbSeventh.vue'
import Bus from '@/api/bus'
import Vue from 'vue'
import { Know, courseCheckPlay, getNewLiveshowplaybackByTypes } from '@/api/know'
const know = new Know()
import { getInfo } from '@/api/cookies'
import { getLbClassCourseById } from '@/api/home'
import { OrderClient } from '@/api/orderClient'
const orderClient = new OrderClient()
export default {
  components: { KindChooseView },
  data() {
    return {
      search: {},
      list: [],
      total: 0,
      userInfo: null,
      pageSize: 12,
      pageNum: 1,
      loading: null,
      ifHave: false,
      liveId: null,
      liveshowName: '',
      live: null,
      lookDeatil: null,
      showLookDetail: false
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  created() {
    this.userInfo = getInfo()
    // 调用轮播图导航接口方法
    this.getList()
  },
  methods: {
    // 获取列表
    async getList() {
      this.loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
      const param = {
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        schoolId: this.search.schoolId,
        kind: this.search.kind,
        zyId: this.search.zyId,
        zStage: this.search.kind == 1 ? '专升本' : this.search.stageName
      }
      await know.getNewLiveshowplaybackByTypes(param)
        .then((res) => {
          this.total = res.total
          this.list = res.rows
        })
      this.loading.close()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    // 筛选
    isOk(val) {
      this.search = val
      this.getList()
    },
    // 直播课详情
    zhiboke(item) {
      this.$router.push(`/zhiboclassxq?id=${item.id}&&type=2`)
    },
    async godetail(item) {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(true)
        return
      }
      this.liveId = item.liveId
      this.liveshowName = item.liveshowName
      await getLbClassCourseById(item.liveId).then((res) => {
        this.ifHave = !res.data.IsPurchase
        this.live = res.data.details
      })
      if (this.ifHave) {
        return
      }
      if (item.isLive == 0) {
        this.$message.error('课程还未到开播时间')
        return
      }
      if (item.isLive == 2) {
        this.$message.error('课程直播结束')
        return
      }
      // this.$router.push({
      //   path: '/livebofang',
      //   query: {
      //     id: item.id,
      //     startTime: item.startTime,
      //   },
      // })
      courseCheckPlay({
        courseType: '1',
        userId: this.userInfo.id,
        chapterId: item.id,
        source: 1
      }).then((res) => {
        if (res.code == 0) {
          const routeUrl = this.$router.resolve({
            path: `/livebofang`,
            query: {
              id: item.id,
              startTime: item.startTime,
              chapterName: item.title
            }
          })
          if (
            /Safari/.test(navigator.userAgent) &&
            !/Chrome/.test(navigator.userAgent)
          ) {
            window.location.href = routeUrl.href
          } else {
            window.open(routeUrl.href, '_blank')
          }
        } else {
          this.lookDeatil = res.msg
          this.showLookDetail = true
        }
      })
    },
    closeLook() {
      this.showLookDetail = false
    },
    // 立即购买
    goOrder() {
      if (this.userInfo) {
        orderClient
          .addOrders(
            this.liveId,
            this.userInfo.id,
            this.live.title,
            2,
            this.live.title,
            this.live.kind
          )
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg }
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
      }
    }
  }
}
</script>
<style lang="less" scoped>
// 最近直播  内容
.live {
  width: calc(1200px + 25px);
  margin-left: calc(50% - 600px);
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .item {
    width: 280px;
    height: 261px;
    background: #ffffff;
    border-radius: 8px;
    margin-right: 25px;
    margin-bottom: 24px;
    overflow: hidden;
    position: relative;
    .clickNum {
      position: absolute;
      top: 8px;
      left: 8px;
      display: flex;
      align-items: center;
      .item-img {
        width: 22px;
        height: 22px;
        flex-shrink: 0;
        z-index: 100;
      }
      .num {
        left: 8px;
        position: absolute;
        height: 22px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 22px;
        padding: 0px 4px 0px 18px;
        z-index: 10;
        width: max-content;
      }
    }
    .item-img {
      width: 100%;
      height: 162px;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
    }
    .detail {
      padding: 8px 12px;
      .detail-title {
        height: 44px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
      .bottom {
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        line-height: 12px;

        .tips {
          color: #4a6af0;
          .iconfont {
            font-size: 12px;
            color: #4a6af0;
            margin-right: 4px;
          }
        }
        .ter {
          color: #666666;
        }
      }
    }
  }
}
.home-two-title {
  width: 1220px;
}
.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
    .contents {
      width: 380px;
      margin: 20px auto;
      & > span {
        color: red;
      }
    }
    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}
</style>
